.s.buttonStandart {
  display: inline-block;
  background-color: antiquewhite;
  color: black;
  font-size: 1rem;
}

.buttonAction {
  background-color: #FFC107;
  color: #000;
  display: inline-block;
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 16px;
  border: 2px solid #f4b803;
  cursor: pointer;
}

.buttonFiltr {
  background-color: #3999dc;
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  padding: 4px 6px;
  font-size: 15px;
  border: 1px solid #265e84;
  cursor: pointer;
  width: 180px;
  height: 35px;
}

.buttonFiltr:hover {
  background-color: #0f86db;
  color: #fff;
  border: 1px solid #268ed8;
}

.btnLogOut {
  text-align: center;
  margin-top: 15px;
}

.btnLogOut span {
  font-size: 14px;
}


.btnLogOut button  {
  width: 90px;
  height: 25px;
  cursor: pointer;
  background-color:#FFC107;
}