html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}

html {
  height: 101%;
  overflow-x: hidden;
}

/* always display scrollbars */
body {
  font-size: 62.5%;
  line-height: 1;
  font-family: 'Geologica', sans-serif;
  background-color: #f6f7f7;
  height: 100%;
}

.App {
  height: 100%;
}

#root {
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

strong {
  font-weight: bold;
}

input {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  max-width: 100%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul {
  font-size: 1.0rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

hr {
  width: 100%;
  color: rgb(253, 207, 55);
  size: 2;
}

.wrapperContent {
  margin: 0;
  padding: 0;
  border: none;
  overflow: unset;
  display: block;
}

/* стили для label */
label {
  display: block;
  margin-bottom: 0.25rem;
}

header {
  height: 100%;
}

.sidebarContent {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 30px;
  align-items: start;
}

.sidebarGridBlock {
  position: sticky;
  top: 15px;
  z-index: 1;
}

.siteContent {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.contenGridBlock {
  position: relative;
  z-index: 0;
}

.VkIdWebSdk__button_reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.VkIdWebSdk__button {
  background: #0077ff;
  cursor: pointer;
  transition: all .1s ease-out;
}

.VkIdWebSdk__button:hover {
  opacity: 0.8;
}

.VkIdWebSdk__button:active {
  opacity: .7;
  transform: scale(.97);
}

.VkIdWebSdk__button {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
}

.VkIdWebSdk__button_container {
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.VkIdWebSdk__button_icon+.VkIdWebSdk__button_text {
  margin-left: -28px;
}

.VkIdWebSdk__button_text {
  display: flex;
  font-family: -apple-system, system-ui, "Helvetica Neue", Roboto, sans-serif;
  flex: 1;
  justify-content: center;
  color: #ffffff;
}