.userTrust button {
    font-size: 0.875rem;
    padding: 7px 15px;
    background-color: rgb(12, 62, 136);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    border: none;
}

.dopFotoUserPage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.dopFotoUserPage .itemFotoUser img {
    width: 150px;
}

.itemFotoUser img {
    border-radius: 10px;
}

.userPageBlock {
    position: relative;
    border-radius: 20px;
    border: 2px solid #FFF;
    background: #FFF;
    color: #000;
    box-shadow: 0px 5px 20px rgba(90, 113, 208, 0.16);
    padding: 20px;
}

.userPageBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.nameUser {
    font-size: 1.2rem;
    color: #000;
}

.contentUserPage {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 20px;
    max-width: 930px;
    margin: 140px auto;
    align-items: start;
}

.avatarUserPage {
    margin-bottom: 20px;
}
.nameUser, .userTrust, .userPageSoialLink {
    margin-bottom: 25px;
}
.userPageSoialLink img {
    width: 40px;
    margin-right: 10px;
}
.titleSocialLink {
    font-size: 0.875rem;
    margin-bottom: 10px;
}
.titleMyAds h2, .userPageBlockUserContent h2 {
    font-size: 2rem;
    margin: 60px 0;
    border-bottom: solid 2px #000;
    display: inline-block;
}
.itemIcon {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid #FFF;
    background: #FFF;
    color: #000;
    box-shadow: 0px 5px 20px rgba(90, 113, 208, 0.16);
    padding: 20px;
}
.itemIcon a {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.itemIcon a img {
    width: 64px;
}
.itemIcon a span {
    font-size: 1rem;
    color: #000;
}