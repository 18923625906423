.blockSidebar {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 20px rgba(90, 113, 208, 0.16);
    padding: 20px;
    margin-bottom: 20px;
}
.titleLinkArticlesSidebar a {
    font-size: 23px;
    color: rgb(15, 15, 15);
}
.titleLinkArticlesSidebar, .imgLinkArticlesSidebar, .descriptionArticles {
    margin-bottom: 15px;
}
.descriptionArticles {
    font-size: 16px;
}
.btnArticles a {
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid;
    color: #0c3e88;
}
.imgLinkArticlesSidebar img {
    width: 260px;
    height: 200px;
    object-fit: cover;
}