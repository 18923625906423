header h1 {
  color: rgb(255 255 255);
  font-size: 2.8rem;
  text-align: center;
  max-width: 660px;
}

.btnNewTours {
  background-color: #FFC107;
  font-size: 2rem;
  color: black;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.btnNewTours:hover {
  background-color: #e99a10;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  transition: all 0.3s ease-out;
}

.headerBackground {
  background: url(/src/assets/images/header/mountains-in-the-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 280px;
}

.btnHeader {
  display: grid;
  grid-template-columns: 230px 230px;
  grid-gap: 20px;
  justify-content: center;
}

.header {
  margin-bottom: 40px;
  padding: 15px;
}

.gridHeaderRowTwo {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-gap: 30px;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.iconUser img {
  border-radius: 50%;
  border: #fff solid;
  width: 130px;
  height: 130px;
  object-fit: cover;
  text-align: center;
}

.iconUserVip img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid transparent;
  background-image: linear-gradient(90deg, red 0%, blue 100%);
  background-origin: border-box;
  box-shadow: inset 0 100vw white;
}

.iconUser,
.name,
.btnSigIn {
  text-align: center;
  margin: 0 5px;
}

.name span {
  font-size: 1rem;
  color: #fff;
  margin-top: 15px;
  display: block;
}

.btnSigIn a {
  font-size: 1rem;
  padding: 7px 15px;
  background-color: #347ce7;
  border-radius: 10px;
  display: inline-block;
  margin-top: 15px;
  color: #fff;
}

.helloName {
  text-align: center;
}