.itemCartTours {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
}

.blockFiltrBtn span.btnPoryadok {
    font-size: 1rem;
    text-align: left;
}

.blockFiltrBtn {
    display: inline-block;
    position: relative;
    z-index: 2;
}

.hovered {
    background-color: #ffffff;
    color: #000000;
}

.podMenuPorydok {
    /* background-color: #fff; */
    font-size: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
}

.podMenuPorydok a {
    text-align: left;
}

.btnPoryadok a {
    display: flex;
    color: #ffffff;
    padding: 5px 9px;
    text-align: center;
    line-height: 0px;
    border-radius: 5px;
    background-color: #2580c9;
    align-items: center;
    justify-content: center;
}

.podMenuPorydok a {
    width: 100%;
    display: inline-block;
    font-size: 1rem;
    color: #ffffff;
    padding: 5px 9px;
    text-align: center;
    line-height: 20px;
}

/* .btnPoryadok a {
    background-color: #ffffff;
} */

.podMenuPorydok a {
    background-color: #ffffff;
    color: #000000;
}

.buttonHeadListTour {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(175px, 190px));
}

.imgIconButton {
    width: 20px;
    margin-right: 10px;
}