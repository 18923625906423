$color: #707070;
$color_focus: #383838;

.input {
  width: 0px;
}

.container {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 0.14rem dashed $color;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  padding: 16px;
  color: $color;
}

.box {
  width: 100%;
  height: 100%;;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.box__margin {
  margin: 8px;
}

.box__image {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.icon {
  fill: $color;
}

@keyframes marquee {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.icon.upload {
  animation: marquee 2s infinite alternate;
}

.icon.delete {
  display: block;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 2;
}

.margin {
  margin-top: 8px;
  margin-bottom: 16px;
}

.container.focus {
  color: $color_focus;
  border-color: $color_focus;
}

.icon.focus {
  fill: $color_focus;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}